import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

function EmptyRecentActivityContent({ image, title, description }) {
  return (
    <>
      <div className="no-content-image d-flex justify-content-center align-items-center">
        <div className="empty-page-wrapper text-center d-block mt-5 mb-5">
          <div className="symbol symbol-250">
            <SVG src={toAbsoluteUrl(image)}></SVG>
          </div>
          <div className="font-weight-bolder fs-18 mt-3">{title}</div>
          <div className="font-weight-normal fs-14 text-muted mt-4 mb-8">
            {description}
          </div>
        </div>
      </div>
    </>
  );
}
export default EmptyRecentActivityContent;
