import React from "react";
import { thumbInnerStyle, thumbsContainerStyle, thumbStyle } from "./CSSMaster";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export const ThumbnailWrapper = ({ file, handleOnDeleteOfAttachment }) => {
  return (
    <aside style={thumbsContainerStyle}>
      <div style={{ width: "100px" }}>
        <div style={thumbStyle}>
          <div style={thumbInnerStyle}>
            <SVG src={toAbsoluteUrl("/media/svg/files/csv.svg")} />

            <i
              className="fas fa-times text-danger"
              onClick={() => {
                handleOnDeleteOfAttachment(file);
              }}
            ></i>
          </div>
        </div>
        <p className="mt-2" style={{ fontSize: "12px", lineHeight: "16px" }}>
          {file}
        </p>
      </div>
    </aside>
  );
};
