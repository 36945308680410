// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../HistoryUIHelpers";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  sortCaret,
} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useHistoryUIContext } from "../HistoryUIContext";

export function HistoryTable({
  selectedHistoryList,
  isLoading,
  historyList,
  historyListTotalCount,
}) {
  const historyUIContext = useHistoryUIContext();
  const historyUIProps = useMemo(() => {
    return {
      ids: historyUIContext.ids,
      setIds: historyUIContext.setIds,
      queryParams: historyUIContext.queryParams,
      setQueryParams: historyUIContext.setQueryParams,
      openViewDialog: historyUIContext.openViewDialog,
      openEditDialog: historyUIContext.openEditDialog,
      openDeleteDialog: historyUIContext.openDeleteDialog,
      selectedHistoryList : selectedHistoryList
    };
  }, [historyUIContext]);

  // Table columns
  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   classes: "notresponsive",
    //   style: {
    //     minWidth: "150px",
    //   },
    // },
    {
      dataField: "material_code",
      text: "MATERIAL CODE",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "180px",
      },
    },
    {
      dataField: "serial_number",
      text: "SERIAL NUMBER",
      sort: true,
      sortCaret: sortCaret,
      attrs: {
        "data-label": "SERIAL NUMBER",
      },
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "dealer_code",
      text: "DEALER CODE",
      sort: true,
      sortCaret: sortCaret,
      style: {
        minWidth: "150px",
      },
    },
    {
      dataField: "is_validate",
      text: "STATUS",
      formatter: columnFormatters.StatusTypeFormatter,
      attrs: {
        "data-label": "STATUS",
      },
      style: {
        minWidth: "150px",
      },
    },

    {
      dataField: "validate_date",
      text: "VALIDATE DATE",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DateTimeColumnFormatter,
      style: {
        minWidth: "170px",
      },
      attrs: {
        "data-label": "DATE",
      },
    },

    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openViewDialog: historyUIProps.openViewDialog,
        openEditDialog: historyUIProps.openEditDialog,
        openDeleteDialog: historyUIProps.openDeleteDialog,
        selectedHistoryList : selectedHistoryList
      },
      classes: "pr-md-0 text-right notresponsive",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "200px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: historyListTotalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: historyUIProps.queryParams.pageSize,
    page: historyUIProps.queryParams.pageNumber,
    onPageChange: (page) => {
      if (page === 0) return;
    },
  };

  // select row in bootstrap table.
  const selectRow = {
    mode: 'checkbox',
    selectColumnStyle: { minWidth: '20px' },
    headerColumnStyle: { minWidth: '20px' },
    onSelectAll: (isSelect, rows, e) => {
      if(isSelect){
        rows.map((data)=>{
          selectedHistoryList.push(data.id)
        })
      }
      if(!isSelect){
        rows.map((data)=>{
          if(selectedHistoryList.includes(data.id))
          {
            const index = selectedHistoryList.indexOf(data.id);
          if (index > -1) {
            selectedHistoryList.splice(index, 1);
          }
          }
        })
      }

    },
    onSelect: (row, isSelect, rowIndex, e) => {
      if(isSelect)
      selectedHistoryList.push(row.id)
        if(!isSelect  && selectedHistoryList.includes(row.id) ){
          const index = selectedHistoryList.indexOf(row.id);
          if (index > -1) {
            selectedHistoryList.splice(index, 1);
          }
        }
    },
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-head-bg table-borderless table-vertical-center history-table"
                bootstrap4
                selectRow={ selectRow }
                bordered={false}
                remote
                keyField="id"
                data={historyList ? historyList : []}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  historyUIProps.setQueryParams
                )}
                // onSelect = {  }
                {...paginationTableProps}
              >

              </BootstrapTable>
              {/* <PleaseWaitMessage entities={historyList} /> */}
              <NoRecordsFoundMessage entities={historyList} />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
