import React, { useEffect, useState } from "react";
import {
  accessLogListApi,
  exportAccessLogApi,
} from "../../../axios/services/services";
import { AccessLogCard } from "./AccessLogCard";
import { AccessLogUIProvider } from "./AccessLogUIContext";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import ErrorModal from "../Modal/ErrorModal";
import { isUnauthorized } from "../../utils/utils";
import UpdatedSuccessfulModal from "../Modal/UpdatedSuccessfulModal";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { initialFilter } from "./AccessLogUIHelpers";
import { STR_CSV } from "../../utils/const";
import { AccessLogViewModal } from "./accesslog-view-modal/AccessLogViewModal";

export function AccessLogPage({ history }) {
  const [isLoadingForExportAccessLog, setLoadingForExportAccessLog] = useState(
    false
  );
  const [isViewModal, setViewModal] = useState(false);
  const [data, setData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [accessLogList, setAccessLogList] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [accessLogListTotalCount, setAccessLogListTotalCount] = useState(0);
  const [updateAccessLogList, setUpdateAccessLogList] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);

  async function getAccessLogList() {
    enableLoading();
    const accessLogListResponse = await accessLogListApi({
      queryParams,
    });
    disableLoading();
    if (accessLogListResponse.Error) {
      isUnauthorized({ response: accessLogListResponse });
      setErrorMessage(accessLogListResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    setAccessLogListTotalCount(
      accessLogListResponse && accessLogListResponse.count
        ? accessLogListResponse.count
        : 0
    );
    setAccessLogList(
      accessLogListResponse && accessLogListResponse.data
        ? accessLogListResponse.data
        : []
    );
  }

  useEffect(() => {
    getAccessLogList();
  }, [queryParams, updateAccessLogList]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingExportAccessLog = () => {
    setLoadingForExportAccessLog(true);
  };

  const disableLoadingExportAccessLog = () => {
    setLoadingForExportAccessLog(false);
  };

  const accessLogUIEvents = {
    openViewDialog: (row) => {
      setData(row);
      handleViewModalShow();
    },
  };

  const handleViewModalShow = () => {
    setViewModal(true);
  };
  const handleViewModalHide = () => {
    setViewModal(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleClickOfExport = async () => {
    enableLoadingExportAccessLog();
    const exportAccessLogResponse = await exportAccessLogApi({
      queryParams,
      filename: `AccessLog-${new Date().getTime()}`,
      extention: STR_CSV,
    });
    disableLoadingExportAccessLog();
    const { Error } = exportAccessLogResponse || {};
    if (Error) {
      isUnauthorized({ response: exportAccessLogResponse });
      setErrorMessage(exportAccessLogResponse.Error.message);
      handleErrorModalShow();
      return;
    }
  };

  return (
    <>
      <AccessLogUIProvider
        accessLogUIEvents={accessLogUIEvents}
        queryParams={queryParams}
        setQueryParamsBase={setQueryParamsBase}
      >
        {isViewModal && (
          <AccessLogViewModal
            data={data}
            handleViewModalHide={handleViewModalHide}
            isViewModal={isViewModal}
          />
        )}

        <AccessLogCard
          isLoading={isLoading}
          isLoadingForExportAccessLog={isLoadingForExportAccessLog}
          accessLogList={accessLogList}
          accessLogListTotalCount={accessLogListTotalCount}
          userInstance={userInstance}
          handleClickOfExport={handleClickOfExport}
        />
      </AccessLogUIProvider>
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
    </>
  );
}
