/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  ContentRoute,
  useHtmlClassService,
} from "../../../../_metronic/layout";
import Login from "./Login";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { connect } from "react-redux";
import * as auth from "../../Auth";

const AuthPage = (props) => {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/* <div
            className="login-aside position-relative d-flex flex-row-auto bgi-size-cover bgi-no-repeat pt-10 pb-10 pl-0 pr-0"
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/bg/pantmany.png"
              )})`,
            }}
          ></div> */}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            <div className="d-flex flex-column-fluid flex-md-center mt-10 mt-md-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`footer bg-white py-4 flex-lg-column login-footer  ${layoutProps.footerClasses}`}
        id="kt_footer"
      >
        <div className={`${layoutProps.footerContainerClasses}`}>
          <div className="text-dark order-2 order-md-1 text-center">
            <div className="footer">
              <div className="left">
                <p className="m-0 text-black">
                  &copy; Akiva. All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, auth.actions)(AuthPage);
