import React from "react";
import { Modal } from "react-bootstrap";
import Moment from "react-moment";
import "moment-timezone";
import { DD_MMM_YYYY_HH_MM } from "../../utils/dateTimeFormats";
import {
  getStatusClassForIsValidate,
  getStatusNameForIsValidate,
} from "../../utils/utils";

function ViewProductModal({ isConfirmModal, handleConfirmHide, title, data }) {
  const { material_code, serial_number, is_validate, validate_date, dealer_code } = data;

  const renderTransactionStatus = () => {
    return (
      <span className="text-dark-75 d-block">
        <span
          className={`${getStatusClassForIsValidate(
            is_validate
          )} status-color text-capitalize`}
        >
          {getStatusNameForIsValidate(is_validate)}{" "}
        </span>
      </span>
    );
  };

  return (
    <>
      <Modal
        show={isConfirmModal}
        onHide={handleConfirmHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center pb-0">
          <h3 className="text-dark font-weight-bold fs-18 text-secondary mb-2">
            {title}
          </h3>

          <div className="row">
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Material Code
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {material_code || "-"}
              </p>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Serial Number
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {serial_number || "-"}
              </p>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Validate Date
              </h5>
              <div className="text-dark fs-14 font-weight-bold text-left">
                {validate_date && (
                  <div>
                    <Moment date={validate_date} format={DD_MMM_YYYY_HH_MM} />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Dealer Code
              </h5>
              <div className="text-dark fs-14 font-weight-bold text-left">
                  {dealer_code || "-"}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Status
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {renderTransactionStatus()}
              </p>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer className="border-0 pt-0">
          <Button
            variant="btn-lg btn-primary mt-5 mb-5 mr-2 px-10"
            onClick={onClickOfCloseButton}
          >
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}
export default ViewProductModal;
