/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openViewDialog, openEditDialog, openDeleteDialog }
) => {
  return (
    <>
      <ButtonToolbar className="view-download-btn justify-content-md-end">
        <Button
          variant="btn btn-light-success font-size-sm"
          onClick={() => {
            openViewDialog(row);
          }}
        >
          <i className="fas fa-eye"></i>
        </Button>
        {/* <Button
          variant="btn btn-light-warning font-size-sm"
          onClick={() => {
            openEditDialog(row);
          }}
        >
          <i className="fas fa-edit"></i>
        </Button>
        <Button
          variant="btn btn-light-danger font-size-sm"
          onClick={() => {
            openDeleteDialog(row);
          }}
        >
          <i className="fas fa-trash-alt"></i>
        </Button> */}
      </ButtonToolbar>
    </>
  );
};
