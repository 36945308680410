/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Moment from "react-moment";
import { DD_MMM_YYYY_HH_MM } from "../../../../utils/dateTimeFormats";
import "moment-timezone";

export const DateTimeColumnFormatter = (cellContent, row, rowIndex) => (
  <>
    <span className="text-dark-75 d-block">
      {row.createdAt ? (
        <Moment date={row.createdAt} format={DD_MMM_YYYY_HH_MM} />
      ) : (
        "-"
      )}
    </span>
    <> </>
  </>
);
