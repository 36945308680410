import React from "react";
import { Button, Card } from "react-bootstrap";
import { HistoryFilter } from "./history-filter/HistoryFilter";
import { HistoryTable } from "./history-table/HistoryTable";
import EmptyRecentActivityContent from "../EmptyPage/EmptyRecentActivityContent";
import { PRODUCTS } from "../../utils/const";
import { ButtonLoader } from "../../utils/ButtonLoader";

export function HistoryCard({
  isLoading,
  historyList,
  selectedHistoryList,
  historyListTotalCount,
  userInstance,
  isLoadingForExportProducts,
  handleClickOfImport,
  handleClickOfExport,
  handleAddNewProduct,
  handleDeleteProduct
}) {
  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center border-bottom mt-4  d-lg-flex">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bold text-dark">{PRODUCTS}</span>
          </h3>
          <div>
            <Button
              type="button"
              className="btn btn-secondary btn-md m-1"
              onClick={handleClickOfImport}
            >
              <i className="fas fa-upload"></i> Import
            </Button>
            <Button
              type="button"
              className="btn btn-secondary btn-md m-1"
              onClick={handleClickOfExport}
            >
              <i className="fas fa-download"></i> Export
              {isLoadingForExportProducts && <ButtonLoader className="ml-3" />}
            </Button>
            <Button
              variant="btn btn-light-primary btn-md m-1"
              onClick={handleAddNewProduct}
            >
              <i className="fas fa-plus"></i> New
            </Button>

            <Button
              variant="btn btn-light-danger btn-md m-1"
              onClick={handleDeleteProduct}
            >
              <i className="fas fa-trash"></i> Multiple Delete
            </Button>

          </div>
        </Card.Header>
        <Card.Body>
          <div>
            <HistoryFilter />
            {historyList && historyList.length > 0 && (
              <HistoryTable
                isLoading={isLoading}
                historyList={historyList}
                selectedHistoryList = {selectedHistoryList}
                historyListTotalCount={historyListTotalCount}
                userInstance={userInstance}
              />
            )}

            {historyList && historyList.length === 0 && (
              <EmptyRecentActivityContent
                image="/media/svg/illustrations/product-not-found.svg"
                title="No Product Available, Yet"
                description="There is no product available."
              />
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
