/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const ResponseColumnFormatter = (cellContent, row, rowIndex) => {
  const { response } = row || {};
  const reponseJson = JSON.parse(response) || {};
  const { responseStatus, responseMessage } = reponseJson || {};
  return (
    <>
      <div>
        <OverlayTrigger
          placement="auto"
          overlay={<Tooltip>{responseMessage}</Tooltip>}
        >
          <span>{responseStatus}</span>
        </OverlayTrigger>
      </div>
      <> </>
    </>
  );
};
