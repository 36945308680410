import React, { useEffect, useState } from "react";
import {
  deleteProductApi,
  exportProductsApi,
  productListApi,
} from "../../../axios/services/services";
import { HistoryReceiptModal } from "./history-receipt-modal/HistoryReceiptModal";
import { HistoryCard } from "./HistoryCard";
import { HistoryUIProvider } from "./HistoryUIContext";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import ErrorModal from "../Modal/ErrorModal";
import { isUnauthorized } from "../../utils/utils";
import UpdatedSuccessfulModal from "../Modal/UpdatedSuccessfulModal";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { initialFilter } from "./HistoryUIHelpers";
import EditProductModal from "../Modal/EditProductModal";
import ConfirmationModal from "../Modal/ConfirmationModal";
import { STR_CSV } from "../../utils/const";
import ImportProductModal from "../Modal/ImportProductModal";
import AddProductModal from "../Modal/AddProductModal";

export function HistoryPage({ history }) {
  const [isLoadingForDeleteProduct, setLoadingForDeleteProduct] = useState(
    false
  );
  const [isLoadingForExportProducts, setLoadingForExportProducts] = useState(
    false
  );
  const [isViewModal, setViewModal] = useState(false);
  const [isAddProductModal, setAddProductModal] = useState(false);
  const [isEditModal, setEditModal] = useState(false);
  const [isImportProductModal, setImportProductModal] = useState(false);
  const [
    isDeleteProductConfirmationModalOpen,
    setDeleteProductConfirmationModalOpen,
  ] = useState(false);
  const [data, setData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [historyList, setHistoryList] = useState(null);
  const [selectedHistoryList, setselectedHistoryList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [historyListTotalCount, setHistoryListTotalCount] = useState(0);
  const [updateHistoryList, setUpdateHistoryList] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);

  async function getProductList() {
    enableLoading();
    console.log("queryParams", queryParams);
    const historyListResponse = await productListApi({ queryParams });
    disableLoading();
    if (historyListResponse.Error) {
      isUnauthorized({ response: historyListResponse });
      setErrorMessage(historyListResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    setHistoryListTotalCount(
      historyListResponse && historyListResponse.count
        ? historyListResponse.count
        : 0
    );
    setHistoryList(
      historyListResponse && historyListResponse.data
        ? historyListResponse.data
        : []
    );
  }

  useEffect(() => {
    getProductList();
  }, [updateHistoryList, queryParams]);

  const handleViewModalShow = () => {
    setViewModal(true);
  };

  const handleViewModalHide = () => {
    setViewModal(false);
  };

  const handleEditModalShow = () => {
    setEditModal(true);
  };

  const handleEditModalHide = () => {
    setEditModal(false);
  };

  const handleAddProductModalShow = () => {
    setAddProductModal(true);
  };

  const handleAddProductModalHide = () => {
    setAddProductModal(false);
  };

  const handleImportProductModalShow = () => {
    setImportProductModal(true);
  };

  const handleImportProductModalHide = () => {
    setImportProductModal(false);
  };

  const handleDeleteProductShow = () => {
    setDeleteProductConfirmationModalOpen(true);
  };

  const handleDeleteProductHide = () => {
    setDeleteProductConfirmationModalOpen(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingDeleteProduct = () => {
    setLoadingForDeleteProduct(true);
  };

  const disableLoadingDeleteProduct = () => {
    setLoadingForDeleteProduct(false);
  };

  const enableLoadingExportProducts = () => {
    setLoadingForExportProducts(true);
  };

  const disableLoadingExportProducts = () => {
    setLoadingForExportProducts(false);
  };

  const historyUIEvents = {
    openViewDialog: (row) => {
      setData(row);
      handleViewModalShow();
    },
    openEditDialog: (row) => {
      setData(row);
      handleEditModalShow();
    },
    openDeleteDialog: (row) => {
      setData(row);
      handleDeleteProductShow();
    },
  };

  const handleCloseDeleteProductConfirmationModal = () => {
    handleDeleteProductHide();
  };

  const OnClickOfOkButtonDeleteProduct = async () => {
    enableLoadingDeleteProduct();
    if(selectedHistoryList.length <= 0){
      setErrorMessage(" Please select at least one product ");
      handleErrorModalShow();
      disableLoadingDeleteProduct();
      return
    }
    const { id } = data;
    console.log(selectedHistoryList);
    let delId = selectedHistoryList;
    const deleteProductResponse = await deleteProductApi({
      id : delId,
    });
    disableLoadingDeleteProduct();
    if (deleteProductResponse.Error) {
      isUnauthorized({ response: deleteProductResponse });
      setErrorMessage(deleteProductResponse.Error.message);
      handleErrorModalShow();
    } else {
      setUpdateHistoryList(Math.random());
      handleSuccessModalShow();
      setSuccessMessage("Product Deleted successfully");
      handleDeleteProductHide();
      //refresh page after delete product.
      window.location.reload(false);
    }
  };

  const handleCloseButtonOfViewProductModal = () => {
    handleViewModalHide();
  };

  const handleSuccessOfEditProduct = () => {
    setUpdateHistoryList(Math.random());
  };

  const handleSuccessOfAddProduct = () => {
    setUpdateHistoryList(Math.random());
  };

  const handleSuccessOfImportProduct = () => {
    setUpdateHistoryList(Math.random());
  };

  const handleAddNewProduct = () => {
    handleAddProductModalShow();
  };
  const handleDeleteProduct = () => {
    handleDeleteProductShow();
  };

  const handleClickOfImport = () => {
    handleImportProductModalShow();
  };

  const handleClickOfExport = async () => {
    enableLoadingExportProducts();
    const exportProductsResponse = await exportProductsApi({
      queryParams,
      filename: `products-${new Date().getTime()}`,
      extention: STR_CSV,
    });
    disableLoadingExportProducts();
    const { Error } = exportProductsResponse || {};
    if (Error) {
      isUnauthorized({ response: exportProductsResponse });
      setErrorMessage(exportProductsResponse.Error.message);
      handleErrorModalShow();
      return;
    }
  };

  return (
    <>
      <HistoryUIProvider
        historyUIEvents={historyUIEvents}
        queryParams={queryParams}
        setQueryParamsBase={setQueryParamsBase}
      >
        {isViewModal && (
          <HistoryReceiptModal
            data={data}
            handleConfirmHide={handleViewModalHide}
            isConfirmModal={isViewModal}
            onClickOfCloseButton={handleCloseButtonOfViewProductModal}
          />
        )}

        {isAddProductModal && (
          <AddProductModal
            isAddProductModalShow={isAddProductModal}
            data={data}
            hideAddProductModal={handleAddProductModalHide}
            handleSuccessOfAddProduct={handleSuccessOfAddProduct}
          />
        )}

        {isEditModal && (
          <EditProductModal
            isEditProductModalShow={isEditModal}
            data={data}
            hideEditProductModal={handleEditModalHide}
            handleSuccessOfEditProduct={handleSuccessOfEditProduct}
          />
        )}

        {isImportProductModal && (
          <ImportProductModal
            isImportProductModalShow={isImportProductModal}
            hideImportProductModal={handleImportProductModalHide}
            handleSuccessOfImportProduct={handleSuccessOfImportProduct}
          />
        )}

        <ConfirmationModal
          title={"Delete Product"}
          infoMessage={"Are you sure you want to delete the product?"}
          isConfirmationModalOpen={isDeleteProductConfirmationModalOpen}
          handleCloseModal={handleCloseDeleteProductConfirmationModal}
          okButtonLabel={"Delete"}
          OnClickOfOkButton={OnClickOfOkButtonDeleteProduct}
          cancelButtonLabel={"Cancel"}
          isLoading={isLoadingForDeleteProduct}
        />

        <HistoryCard
          isLoading={isLoading}
          historyList={historyList}
          selectedHistoryList={selectedHistoryList}
          historyListTotalCount={historyListTotalCount}
          userInstance={userInstance}
          isLoadingForExportProducts={isLoadingForExportProducts}
          handleClickOfImport={handleClickOfImport}
          handleClickOfExport={handleClickOfExport}
          handleAddNewProduct={handleAddNewProduct}
          handleDeleteProduct={handleDeleteProduct}
        />
      </HistoryUIProvider>
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
    </>
  );
}
