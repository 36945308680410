/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { selectedHistoryList, openViewDialog, openEditDialog, openDeleteDialog }
) => {
  return (
    <>
      <ButtonToolbar className="view-download-btn justify-content-md-end">
        <Button
          variant="btn btn-icon btn-light-success btn-sm mx-1"
          onClick={() => {
            openViewDialog(row);
          }}
        >
          <i className="fas fa-eye"></i>
        </Button>
        <Button
          variant="btn btn-icon btn-light-warning btn-sm mx-1 font-size-sm"
          onClick={() => {
            openEditDialog(row);
          }}
        >
          <i className="fas fa-edit"></i>
        </Button>
        <Button
          variant="btn btn-icon btn-light-danger btn-sm mx-1 font-size-sm"
          onClick={() => {
            // pass single Id which needs to be delete.
            selectedHistoryList.splice(0,selectedHistoryList.length)
            selectedHistoryList.push(row.id)
            openDeleteDialog(selectedHistoryList);
          }}
        >
          <i className="fas fa-trash-alt"></i>
        </Button>
      </ButtonToolbar>
      <> </>
    </>
  );
};
