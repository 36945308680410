/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {
  getStatusClassForIsValidate,
  getStatusNameForIsValidate,
} from "../../../../utils/utils";
export const StatusTypeFormatter = (cellContent, row, rowIndex) => {
  const { is_validate } = row;

  const renderProductStatus = () => {
    return (
      <span className="text-dark-75 d-block">
        <span
          className={`${getStatusClassForIsValidate(
            is_validate
          )} status-color text-capitalize`}
        >
          {getStatusNameForIsValidate(is_validate)}{" "}
        </span>
      </span>
    );
  };

  return renderProductStatus();
};
