/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Switch } from "react-router-dom";
import { LandingRoutes } from "./modules/Auth/LandingRoutes";

export function Routes(props) {
  return (
    <Switch>
      <LandingRoutes />
    </Switch>
  );
}
