import { SERVER_URL } from "../../app/ServerConfig";

export function loginUrl() {
  let url = "/loginUser";
  return url;
}

export function uploadProfilePicUrl() {
  let url = "/uploads/upload";
  return url;
}

export function recentHistoryUrl() {
  let url = "/transactions/getRecentHistory";
  return url;
}

export function productListUrl({ queryParams }) {
  const { pageNumber, pageSize, filter, sortField, sortOrder } =
    queryParams || {};
  const { material_code, serial_number, is_validate, date , dealer_code} = filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `/getProduct?limit=${pageSize}&offset=${skip}`;

  if (material_code) {
    url = url.concat(`&materialCode=${material_code}`);
  }
  if (serial_number) {
    url = url.concat(`&serialNumber=${serial_number}`);
  }
  if (is_validate) {
    url = url.concat(`&isValidate=${is_validate}`);
  }

  if (dealer_code) {
    url = url.concat(`&dealer_code=${dealer_code}`);
  }
  if (sortField && sortOrder) {
    url = url.concat(`&order=${sortField} ${sortOrder.toUpperCase()}`);
  }

  if (date && date.datefrom) {
    url = url.concat(`&datefrom=${date.datefrom}`);
  }

  if (date && date.dateto) {
    url = url.concat(`&dateto=${date.dateto}`);
  }

  return url;
}

export function accessLogListUrl({ queryParams }) {
  const { pageNumber, pageSize, filter, sortField, sortOrder } =
    queryParams || {};
  const { material_code, serial_number, response_code, date , dealer_code} = filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `/getaccessLog?limit=${pageSize}&offset=${skip}`;

  if (material_code) {
    url = url.concat(`&materialCode=${material_code}`);
  }
  if (serial_number) {
    url = url.concat(`&serialNumber=${serial_number}`);
  }
  
  console.log("dealer_code",dealer_code)
  if (dealer_code) {
    url = url.concat(`&dealer_code=${dealer_code}`);
  }
  if (response_code) {
    url = url.concat(`&responseCode=${response_code}`);
  }

  if (date && date.datefrom) {
    url = url.concat(`&datefrom=${date.datefrom}`);
  }

  if (date && date.dateto) {
    url = url.concat(`&dateto=${date.dateto}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(`&order=${sortField} ${sortOrder.toUpperCase()}`);
  }
  return url;
}

export function getImageBasedOnNameUrl(imageName) {
  let url = `${SERVER_URL}/downloads/download?folder_name=profilepic&image=${imageName}&id=${localStorage.getItem(
    "auth-token"
  )}`;
  return url;
}

export function getPdfBasedOnNameUrl({ file, folder }) {
  let url = `/downloads/download?folder_name=${
    folder ? folder : ""
  }&image=${file}&id=${localStorage.getItem("auth-token")}`;
  return url;
}

export function recentHistoryPDFUrl({ id }) {
  let url = `/transactions/paymentExport/${id}`;
  return url;
}

export function recentAccessLogPDFUrl({ id }) {
  let url = `/transactions/paymentExport/${id}`;
  return url;
}

export function addProductUrl() {
  let url = `/addProduct`;
  return url;
}

export function editProductUrl() {
  let url = `/editProduct`;
  return url;
}

export function logoutUrl() {
  let url = `/Logout
  `;
  return url;
}

export function deleteProductUrl({ id }) {
  let url = `/removeProduct/${id}`;
  return url;
}

export function exportProductsUrl({ queryParams }) {
  const { pageNumber, pageSize, filter, sortField, sortOrder } =
    queryParams || {};
  const { material_code, serial_number, is_validate, date } = filter || {};
  let skip = (pageNumber - 1) * pageSize;

  let url = `/exportCSV?limit=${pageSize}&offset=${skip}`;

  if (material_code) {
    url = url.concat(`&materialCode=${material_code}`);
  }

  if (serial_number) {
    url = url.concat(`&serialNumber=${serial_number}`);
  }

  if (is_validate) {
    url = url.concat(`&isValidate=${is_validate}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(`&order=${sortField} ${sortOrder.toUpperCase()}`);
  }

  if (date && date.datefrom) {
    url = url.concat(`&datefrom=${date.datefrom}`);
  }

  if (date && date.dateto) {
    url = url.concat(`&dateto=${date.dateto}`);
  }

  return url;
}

export function exportAccessLogUrl({ queryParams }) {
  const { pageNumber, pageSize, filter, sortField, sortOrder } =
    queryParams || {};
  const { material_code, serial_number, response_code, dealer_code ,is_validate, date } =
    filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `/exportlogs?limit=${pageSize}&offset=${skip}`;

  if (material_code) {
    url = url.concat(`&materialCode=${material_code}`);
  }
  if (serial_number) {
    url = url.concat(`&serialNumber=${serial_number}`);
  }
  if (response_code) {
    url = url.concat(`&responseCode=${response_code}`);
  }

  if (dealer_code) {
    url = url.concat(`&dealer_code=${dealer_code}`);
  } 
  if (date && date.datefrom) {
    url = url.concat(`&datefrom=${date.datefrom}`);
  }

  if (date && date.dateto) {
    url = url.concat(`&dateto=${date.dateto}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(`&order=${sortField} ${sortOrder.toUpperCase()}`);
  }
  return url;
}

export function uploadDocumentUrl() {
  let url = `/upload`;
  return url;
}

export function importProductsUrl() {
  let url = `/importProducts`;
  return url;
}
