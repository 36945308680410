export const MMMM_DO_YYYY_H_MM_SS_A = "MMMM Do YYYY, h:mm:ss a";
export const DDD = "ddd";
export const MMM_DD = "MMM DD";
export const MMMM_DO_YYYY = "MMMM Do, YYYY";
export const HH_MMA = "hh:mma";
export const WEEK = "week";
export const MM_DD_YYYY = "MM/DD/YYYY";
export const MM_DD_YY = "MM/DD/YY";
export const MM_DD_YY_hh_mm_a = "MM/DD/YY hh:mm a";
export const MM_DD_YY_hh_mm_A = "MM/DD/YY hh:mm A";
export const DD_MMM_YYYY_HH_MM = "DD/MM/YYYY hh:mm A";
export const HH_MM_A = "HH:MM A";
export const TIME_ZONE = "America/New_York";
