export const API = "api";
export const PRODUCTS = "Products";
export const ACCESS_LOG = "Access Log";
export const YYYY_MM_DD = "YYYY-MM-DD";
export const YYYY_MM_DD_HH_MM = "YYYY-MM-DD HH:MM";
export const dd_MM_yyyy_HH_MM = "dd/MM/yyyy HH:MM";
export const dd_MM_yyyy = "dd/MM/yyyy";
export const STR_EXCEL = "xlsx";
export const STR_CSV = "csv";
export const FILE_EXTENTION = "application/pdf,.doc,.docx,.xlsx,.xls";
export const MB_20 = 20971520;
