import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { useHistoryUIContext } from "../HistoryUIContext";
import { isEqual } from "lodash";
import DatePicker from "react-datepicker";
import { dd_MM_yyyy, YYYY_MM_DD } from "../../../utils/const";
import moment from "moment";

const initialFilterValues = {
  material_code: "",
  serial_number: "",
  dealer_code:"",
  is_validate: "",
  datefrom: "",
  dateto: "",
};

export function HistoryFilter({}) {
  const [datefrom, setDateFrom] = useState("");
  const [dateto, setDateTo] = useState("");

  const historyUIContext = useHistoryUIContext();
  const historyUIUIProps = useMemo(() => {
    return {
      queryParams: historyUIContext.queryParams,
      setQueryParams: historyUIContext.setQueryParams,
    };
  }, [historyUIContext]);

  const prepareFilter = (queryParams, values) => {
    const {
      material_code,
      serial_number,
      dealer_code,
      is_validate,
      datefrom,
      dateto,
    } = values;

    const newQueryParams = { ...queryParams };
    const filter = {};

    filter.material_code = material_code !== "" ? material_code : undefined;
    filter.serial_number = serial_number !== "" ? serial_number : undefined;
    filter.dealer_code = dealer_code !== "" ? dealer_code : undefined
    filter.is_validate = is_validate !== "" ? is_validate : undefined;
    if (datefrom && dateto) {
      const date = {
        datefrom: datefrom ? moment(datefrom).format(YYYY_MM_DD) : undefined,
        dateto: dateto ? moment(dateto).format(YYYY_MM_DD) : undefined,
      };
      filter.date = date !== "" ? date : undefined;
    }

    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const applyFilter = (values) => {
    console.log("-values->",values)
    const newQueryParams = prepareFilter(historyUIUIProps.queryParams, values);
    if (!isEqual(newQueryParams, historyUIUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      historyUIUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          applyFilter(values);
        }}
        onReset={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
          handleReset,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="form form-label-right"
            autoComplete="off"
            onReset={() => {
              resetForm(initialFilterValues);
              setDateFrom(null);
              setDateTo(null);
              handleReset();
            }}
          >
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="material_code"
                  placeholder="Search Material Code"
                  onBlur={handleBlur}
                  value={values.material_code}
                  onChange={(e) => {
                    setFieldValue("material_code", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> Material Code
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="serial_number"
                  placeholder="Search Serial Number"
                  onBlur={handleBlur}
                  value={values.serial_number}
                  onChange={(e) => {
                    setFieldValue("serial_number", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> Serial Number
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="dealer_code"
                  placeholder="Search Dealer Code"
                  onBlur={handleBlur}
                  value={values.dealer_code}
                  onChange={(e) => {
                    setFieldValue("dealer_code", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> Dealer Code
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  name="is_validate"
                  placeholder="Filter by Validate"
                  onChange={(e) => {
                    setFieldValue("is_validate", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.is_validate}
                >
                  <option value="">All</option>
                  <option value="1">Validated</option>
                  <option value="0">Not Validated</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Status
                </small>
              </div>

              <div className="col-lg-2">
                <DatePicker
                  dateFormat={dd_MM_yyyy}
                  className="mb-1 form-control"
                  selected={datefrom}
                  onChange={(date) => {
                    setDateFrom(date);
                    setFieldValue("datefrom", date);
                    handleSubmit();
                  }}
                  placeholderText={"From"}
                  selectsStart
                  startDate={datefrom}
                  endDate={dateto}
                />
                <small className="form-text text-muted">
                  <b>Filter</b> by Validate Date
                </small>
              </div>

              <div className="col-lg-2">
                <DatePicker
                  className="form-control"
                  dateFormat={dd_MM_yyyy}
                  selected={dateto}
                  onChange={(date) => {
                    setDateTo(date);
                    setFieldValue("dateto", date);
                    handleSubmit();
                  }}
                  placeholderText={"To"}
                  selectsEnd
                  startDate={datefrom}
                  endDate={dateto}
                  minDate={datefrom}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
