import React from "react";
import { onErrorImg } from "./onErrorImg";
export function CustomImage({ classNames, src, alt }) {
  return (
    <img
      className={classNames ? classNames : ""}
      src={src}
      alt={alt ? alt : ""}
      onError={onErrorImg}
    />
  );
}
