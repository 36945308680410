import {
  deleteCall,
  getCall,
  getExcelCall,
  getPDFCall,
  patchCall,
  postCall,
  postCallWithMedia,
} from "../api";
import {
  accessLogListUrl,
  addProductUrl,
  deleteProductUrl,
  editProductUrl,
  exportAccessLogUrl,
  exportProductsUrl,
  importProductsUrl,
  loginUrl,
  logoutUrl,
  productListUrl,
  recentAccessLogPDFUrl,
  recentHistoryPDFUrl,
  recentHistoryUrl,
  uploadDocumentUrl,
  uploadProfilePicUrl,
} from "./apiList";
import {
  addProductObj,
  editProductObj,
  importProductsObj,
  loginObj,
  uploadDocumentObj,
  uploadProfilePicObj,
} from "./apiObjects";

export function loginApi({ email, password, role }) {
  return postCall(loginUrl(), loginObj({ email, password, role }));
}

export function uploadProfilePicApi({ file, containerkey }) {
  return postCallWithMedia(
    uploadProfilePicUrl(),
    uploadProfilePicObj({ file }),
    containerkey
  );
}

export function recentHistoryApi() {
  return getCall(recentHistoryUrl());
}

export function productListApi({ queryParams }) {
  return getCall(productListUrl({ queryParams }));
}

export function deleteProductApi({ id }) {
  return deleteCall(deleteProductUrl({ id }));
}

export function accessLogListApi({ queryParams }) {
  return getCall(accessLogListUrl({ queryParams }));
}

export function addProductApi({ materialCode, serialNumber,dealer_code }) {
  return postCall(
    addProductUrl(),
    addProductObj({
      materialCode,
      serialNumber,
      dealer_code,
    })
  );
}

export function editProductApi({
  id,
  materialCode,
  serialNumber,
  is_validate,
  dealer_code,
}) {
  return patchCall(
    editProductUrl(),
    editProductObj({
      id,
      materialCode,
      serialNumber,
      is_validate,
      dealer_code,
    })
  );
}

export function recentHistoryPDFApi({ id, customPdfName }) {
  return getPDFCall(recentHistoryPDFUrl({ id }), customPdfName);
}

export function recentAccessLogPDFApi({ id, customPdfName }) {
  return getPDFCall(recentAccessLogPDFUrl({ id }), customPdfName);
}

export function recentHistoryPrintApi({ id }) {
  return getCall(recentHistoryPDFUrl({ id }));
}

export function logoutApi() {
  return deleteCall(logoutUrl());
}

export function exportProductsApi({ queryParams, filename, extention }) {
  return getPDFCall(exportProductsUrl({ queryParams }), filename, extention);
}

export function exportAccessLogApi({ queryParams, filename, extention }) {
  return getPDFCall(exportAccessLogUrl({ queryParams }), filename, extention);
}

export function uploadDocumentApi(files) {
  return postCallWithMedia(uploadDocumentUrl(), uploadDocumentObj(files));
}

export function importProductsApi({ file }) {
  return postCall(importProductsUrl(), importProductsObj({ file }));
}
