import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import { AccessLogPage } from "./modules/AccessLog/AccessLogPage";
import { HistoryPage } from "./modules/History/HistoryPage";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/auth/login" to="/dashboard" />
        }

        <Route path="/dashboard" component={HistoryPage} />
        <Route path="/access-log" component={AccessLogPage} />
        {/* <Redirect to="error/error-v1" /> */}
      </Switch>
    </Suspense>
  );
}
