import React, { useCallback, useState } from "react";
import { ButtonToolbar } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { useFormik } from "formik";
import { useLocalStorage } from "../../utils/useLocalStorage";
import ErrorModal from "./ErrorModal";
import UpdatedSuccessfulModal from "./UpdatedSuccessfulModal";
import {
  EditProductSchema,
  ImportProductSchema,
} from "../../utils/FormikSchema";
import { useDropzone } from "react-dropzone";
import { FILE_EXTENTION, MB_20 } from "../../utils/const";
import {
  importProductsApi,
  uploadDocumentApi,
} from "../../../axios/services/services";
import { ContentLoader } from "../../utils/ContentLoader";
import { ThumbnailWrapper } from "../../utils/ThumbnailWrapper";
import download from "downloadjs";

const initialValues = {
  product: "",
};

function ImportProductModal({
  isImportProductModalShow,
  hideImportProductModal,
  handleSuccessOfImportProduct,
}) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [isFileUploadLoading, setFileUploadLoading] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: ImportProductSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      const { product } = values;
      enableLoading();
      let importProductsObj = {
        file: product,
      };
      const importProductsResponse = await importProductsApi(importProductsObj);
      disableLoading();
      const { Error } = importProductsResponse || {};
      if (Error) {
        const { message } = Error || {};
        setErrorMessage(message);
        handleErrorModalShow();
        return;
      }
      resetForm();
      handleSuccessOfImportProduct();
      //hold success message for 5 sec.
      setSuccessMessage( `skipped serial numbers : ${importProductsResponse.skipped_record} \n
       Total : ${importProductsResponse.Records_Inserted} Inserted`);
        handleSuccessModalShow();
      setTimeout(() => {
        hideImportProductModal();
      }, 5000);
    },
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingFileUpload = () => {
    setFileUploadLoading(true);
  };

  const disableLoadingFileUpload = () => {
    setFileUploadLoading(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };
  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const attachDocument = async (acceptedFiles) => {
    const uploadedFilesArray = [];
    enableLoadingFileUpload();
    let files = {
      file: acceptedFiles[0],
      containername: "product",
    };

    const uploadDocumentResponse = await uploadDocumentApi(files);
    const { File } = uploadDocumentResponse || {};
    formik.setFieldValue("product", File);
    uploadedFilesArray.push(File);
    disableLoadingFileUpload();
    setFile(File);
  };

  const onDrop = useCallback((acceptedFiles) => {
    console.log("acceptedFiles", acceptedFiles);
    if (acceptedFiles && acceptedFiles.length === 0) {
      setErrorMessage("Upload Excel File Only or File must be less than 20 MB");
      handleErrorModalShow();
      return;
    }
    attachDocument(acceptedFiles);
  });

  const { getRootProps, getInputProps } = useDropzone({
    disabled: file !== null,
    accept: FILE_EXTENTION,
    maxFiles: 1,
    maxSize: MB_20,
    maxFilesize: MB_20,
    uploadMultiple: false,
    multiple: false,
    // parallelUploads: 10,
    autoProcessQueue: true,
    onDrop,
  });

  const handleOnDeleteOfAttachment = () => {
    setFile(null);
    formik.setFieldValue("product", "");
  };

  return (
    <>
      <Modal
        show={isImportProductModalShow}
        onHide={() => {
          formik.resetForm();
          hideImportProductModal();
        }}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="d-flex justify-content-between w-100"
            id="contained-modal-title-vcenter"
          >
            Import Products
            <Button
              variant="btn p-0 d-flex justify-content-end"
              onClick={hideImportProductModal}
            >
              <span className="svg-icon svg-icon-secondary svg-icon-2x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <div className="form-group mt-2">
              <div
                {...getRootProps({
                  className: "dropzone needsclick dz-clickable text-center p-4",
                })}
              >
                <input {...getInputProps()} />
                <i
                  className="fas fa-file-excel text-dark"
                  style={{ strokeWidth: "1.5px" }}
                ></i>
                <p style={{ fontSize: "13px" }}>
                  Drag/Drop Files Here or Click The Icon
                </p>
              </div>
              {isFileUploadLoading && <ContentLoader />}
              {file && (
                <ThumbnailWrapper
                  file={file}
                  handleOnDeleteOfAttachment={handleOnDeleteOfAttachment}
                />
              )}

              {formik.touched.product && formik.errors.product ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.product}</div>
                </div>
              ) : null}
              <p
                className="cursor-pointer text-danger mt-3 text-right"
                onClick={() => {
                  download(
                    toAbsoluteUrl("/media/sample-doc/SampleImportProduct.csv")
                  );
                }}
              >
                Download Sample <i className="fas fa-download text-danger"></i>
              </p>
            </div>

            <ButtonToolbar className="d-flex justify-content-end button-wrap">
              <Button
                type="submit"
                variant="btn btn-success mr-3"
                disabled={loading}
              >
                Import Products
                {loading && (
                  <span className="ml-3 mr-3 spinner spinner-white"></span>
                )}
              </Button>
              <Button
                variant="btn btn-light-dark"
                onClick={() => {
                  formik.resetForm();
                  hideImportProductModal();
                }}
                disabled={loading}
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </Modal.Body>
      </Modal>
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
    </>
  );
}
export default ImportProductModal;
