import React from "react";
import { Modal } from "react-bootstrap";
import Moment from "react-moment";
import { DD_MMM_YYYY_HH_MM } from "../../../utils/dateTimeFormats";

export function AccessLogViewModal({ data, handleViewModalHide, isViewModal }) {
  const {
    access_key,
    createdAt,
    material_code,
    raw_JSON,
    request,
    response,
    response_code,
    serial_number,
    dealer_code,
  } = data || {};
  return (
    <>
      <Modal
        show={isViewModal}
        onHide={handleViewModalHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center pb-0">
          <h3 className="text-dark font-weight-bold fs-18 text-secondary mb-2">
            View Access Log
          </h3>

          <div className="row">
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Material Code
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {material_code || "-"}
              </p>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Serial Number
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {serial_number || "-"}
              </p>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Dealer Code
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {dealer_code || "-"}
              </p>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Validate Date
              </h5>
              <div className="text-dark fs-14 font-weight-bold text-left">
                {createdAt && (
                  <div>
                    <Moment date={createdAt} format={DD_MMM_YYYY_HH_MM} />
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Request Url
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {request || "-"}
              </p>
            </div>
            {/* <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Request
              </h5>
              <p
                className="text-dark fs-14 font-weight-bold text-left"
                style={{ overflowWrap: "break-word" }}
              >
                {raw_JSON || "-"}
              </p>
            </div> */}
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Response Status
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {response_code}
              </p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
