import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { useLocalStorage } from "../../../../app/utils/useLocalStorage";
import { isUnauthorized } from "../../../../app/utils/utils";
import { shallowEqual, useSelector } from "react-redux";
import ErrorModal from "../../../../app/modules/Modal/ErrorModal";
import { getImageBasedOnNameUrl } from "../../../../axios/services/apiList";
import { CustomImage } from "../../../../app/utils/CustomImage";

export function HeaderMobile() {
  const uiService = useHtmlClassService();
  const subheader = useSubheader();
  const [userDetails, setUserDetails] = useState({});
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
        objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile"),
    };
  }, [uiService]);

  const { user } = useSelector((state) => state.auth, shallowEqual);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <>
      {/*begin::Header Mobile*/}
      <div
        id="kt_header_mobile"
        className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
        {...layoutProps.headerMobileAttributes}
      >
        {/*begin::Logo*/}
        <h5 className="text-white font-weight-boldest my-2 mr-5">
          <>{subheader.title}</>
        </h5>
        {/*end::Logo*/}
        {layoutProps.viewSearchDisplay && <SearchDropdown />}
        {/*begin::Toolbar*/}
        <div className="d-flex align-items-center">
          {layoutProps.asideDisplay && (
            <>
              {/*begin::Aside Mobile Toggle*/}
              <button
                className="btn p-0 burger-icon burger-icon-left"
                id="kt_aside_mobile_toggle"
              >
                <span />
              </button>
              {/*end::Aside Mobile Toggle*/}
            </>
          )}

          {layoutProps.headerMenuSelfDisplay && (
            <>
              {/*begin::Header Menu Mobile Toggle*/}
              <button
                className="btn p-0 burger-icon ml-4"
                id="kt_header_mobile_toggle"
              >
                <span />
              </button>
              {/*end::Header Menu Mobile Toggle*/}
            </>
          )}

          {/*begin::Topbar Mobile Toggle*/}
          <button
            className="btn btn-hover-text-primary p-0 ml-4"
            id="kt_header_mobile_topbar_toggle"
          >
            <span className="symbol symbol-35 symbol-circle">
              <CustomImage src={getImageBasedOnNameUrl(userDetails.profile)} />
              {/* <img src={toAbsoluteUrl("/media/users/300_21.jpg")} alt="" /> */}
            </span>
          </button>
          {/*end::Topbar Mobile Toggle*/}
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Header Mobile*/}
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
    </>
  );
}
