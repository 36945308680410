import React from "react";
import { Button, Card } from "react-bootstrap";
import { AccessLogTable } from "./accesslog-table/AccessLogTable";
import EmptyRecentActivityContent from "../EmptyPage/EmptyRecentActivityContent";
import { ACCESS_LOG } from "../../utils/const";
import { AccessLogFilter } from "./accesslog-filter/AccessLogFilter";
import { ButtonLoader } from "../../utils/ButtonLoader";

export function AccessLogCard({
  isLoading,
  accessLogList,
  handleClickOfExport,
  isLoadingForExportAccessLog,
  accessLogListTotalCount,
  userInstance,
  paginateAccessLogList,
}) {
  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center border-bottom mt-4 d-none d-lg-flex">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bold text-dark">{ACCESS_LOG}</span>
          </h3>
          <Button
            type="button"
            className="btn btn-secondary btn-md"
            onClick={handleClickOfExport}
          >
            <i className="fas fa-download"></i> Export
            {isLoadingForExportAccessLog && <ButtonLoader className="ml-3" />}
          </Button>
        </Card.Header>
        <Card.Body>
          <div>
            <AccessLogFilter />
            {accessLogList && accessLogList.length > 0 && (
              <AccessLogTable
                isLoading={isLoading}
                accessLogList={accessLogList}
                accessLogListTotalCount={accessLogListTotalCount}
                userInstance={userInstance}
                paginateAccessLogList={paginateAccessLogList}
              />
            )}

            {accessLogList && accessLogList.length === 0 && (
              <EmptyRecentActivityContent
                image="/media/svg/illustrations/product-not-found.svg"
                title="No AccessLog Available, Yet"
                description="There is no accesslog available."
              />
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
