import React from "react";
import ViewProductModal from "../../Modal/ViewProductModal";

export function HistoryReceiptModal({
  data,
  isConfirmModal,
  handleConfirmHide,
  onClickOfCloseButton,
}) {
  return (
    <ViewProductModal
      data={data}
      title={"View Product"}
      isConfirmModal={isConfirmModal}
      handleConfirmHide={handleConfirmHide}
      buttonLabel={"Close"}
      onClickOfCloseButton={onClickOfCloseButton}
    />
  );
}
